<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL PERSONAL
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i class="fas fa-user-friends fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaPersonal.length}}</span>
                            <br>
                            <span class="text-muted h6">Personal registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión del personal </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalNuevoPersonal = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo personal
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Personal registrados</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaPersonal" :fields="campoPersonal" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(nombre)="data">
                                        <span> {{ data.item.nombre}} {{data.item.apellidoPaterno}} {{data.item.apellidoMaterno}} </span>
                                    </template>
                                    <template #cell(hasUsuario)="data">
                                        <span v-if="data.value == 2"> SI </span>
                                        <span v-else> NO </span>

                                    </template>
                                    <template #cell(usuario)="data">
                                        <span v-if="data.value"> {{data.value}} </span>
                                        <span v-if="!data.value"> - </span>

                                    </template>
                                    <template #cell(acciones)="param">
                                        <b-button @click="abrirModalModificarPersonal(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                        <b-button @click="abrirModalDocumentacion(param);" class="mr-1 mb-1" size="sm" variant="blue" v-c-tooltip="'Documentación'">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-file-alt my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                        <b-button v-if="param.item.estado==1 || param.item.estadoPersona==1" @click="restaurarPersonal(param)" class="mr-1 mb-1" size="sm" variant="success" v-c-tooltip="'Restaurar'">
                                            <span class="btn-inner--icon text-white">
                                                <i class="fas fa-reply my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                        <b-button v-if="param.item.estado==2 || param.item.estadoPersona==2" @click="eliminarPersonal(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalNuevoPersonal">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo personal </span>
            </h6>
            <CButtonClose @click="modalNuevoPersonal = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(nuevoPersonal)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosNuevoPersonal.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido paterno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido Paterno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el apellido paterno" v-model="datosNuevoPersonal.apellidoPaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido materno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido Materno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el apellido materno" v-model="datosNuevoPersonal.apellidoMaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="correo" :rules="{email: true,required:true}" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el correo" v-model="datosNuevoPersonal.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="teléfono" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el teléfono" v-model="datosNuevoPersonal.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de documento" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Tipo de documento:">
                                <b-form-select :state="getValidationState(validationContext)" value-field="idTipoDocumento" text-field="descripcion" v-model="datosNuevoPersonal.idTipoDocumento" :options="acions">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="documento de identidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Documento de identidad:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el documento de identidad" v-model="datosNuevoPersonal.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Puesto" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto:" class="mb-2">
                                <v-select :reduce="comboPuestosTrabajo => comboPuestosTrabajo.idPuestoTrabajo" label="nombre" placeholder="Seleccione una opción" :class="{'style-valid-select': datosNuevoPersonal.idPuestoTrabajo , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosNuevoPersonal.idPuestoTrabajo" :options="comboPuestosTrabajo">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Requiere usuario:" class="mb-2">
                            <b-form-radio-group v-on:change="generarNombreUsuario()" class="text-center" v-model="datosNuevoPersonal.hasUsuario" :options="[{value: 2, text: 'SI'},{value: 1, text: 'NO'}]" small plain></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="datosNuevoPersonal.hasUsuario ==2">
                        <validation-provider name="tipo de perfil" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de perfil:" class="mb-2">
                                <v-select :reduce="comboTipoPerfiles => comboTipoPerfiles.idTipoPerfil" label="nombre" placeholder="Seleccione una opción" :class="{'style-valid-select': datosNuevoPersonal.idTipoPerfil , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosNuevoPersonal.idTipoPerfil" :options="comboTipoPerfiles">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosNuevoPersonal.hasUsuario ==2">
                        <validation-provider name="usuario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Usuario:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el usuario" v-model="datosNuevoPersonal.usuario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosNuevoPersonal.hasUsuario ==2">
                        <validation-provider name="contraseña" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Contraseña:">
                                <b-form-input type="password" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la contrasena" v-model="datosNuevoPersonal.contrasena"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalNuevoPersonal = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalModificarPersonal">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar personal </span>
            </h6>
            <CButtonClose @click="modalModificarPersonal = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarPersonal)">
                <b-row>
                    <!-- {{datosModificarPersonal}} -->

                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuarioActualizar()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosModificarPersonal.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido paterno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido Paterno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuarioActualizar()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el apellido paterno" v-model="datosModificarPersonal.apellidoPaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido materno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido Materno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuarioActualizar()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el apellido materno" v-model="datosModificarPersonal.apellidoMaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="correo" :rules="{email: true,required:true}" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el correo" v-model="datosModificarPersonal.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="teléfono" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el teléfono" v-model="datosModificarPersonal.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de documento" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Tipo de documento:">
                                <b-form-select :state="getValidationState(validationContext)" value-field="idTipoDocumento" text-field="descripcion" v-model="datosModificarPersonal.idTipoDocumento" :options="acions">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="documento de identidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Documento de identidad:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el documento de identidad" v-model="datosModificarPersonal.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="Puesto" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto:" class="mb-2">
                                <v-select :reduce="comboPuestosTrabajo => comboPuestosTrabajo.idPuestoTrabajo" label="nombre" placeholder="Seleccione una opción" :class="{'style-valid-select': datosModificarPersonal.idPuestoTrabajo , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosModificarPersonal.idPuestoTrabajo" :options="comboPuestosTrabajo">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Requiere usuario:" class="mb-2">
                            <b-form-radio-group v-on:change="generarNombreUsuarioActualizar()" class="text-center" v-model="datosModificarPersonal.hasUsuario" :options="[{value: 2, text: 'SI'},{value: 1, text: 'NO'}]" small plain></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="datosModificarPersonal.hasUsuario ==2">
                        <validation-provider name="tipo de perfil" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de perfil:" class="mb-2">
                                <v-select :reduce="comboTipoPerfiles => comboTipoPerfiles.idTipoPerfil" label="nombre" placeholder="Seleccione una opción" :class="{'style-valid-select': datosModificarPersonal.idTipoPerfil , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarPersonal.idTipoPerfil" :options="comboTipoPerfiles">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosModificarPersonal.hasUsuario ==2">
                        <validation-provider name="usuario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Usuario:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el usuario" v-model="datosModificarPersonal.usuario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6" v-if="datosModificarPersonal.hasUsuario ==2">
                        <validation-provider name="contraseña" v-slot="validationContext">
                            <b-form-group label="Contraseña:">
                                <b-form-input type="password" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la contrasena (opcional)" v-model="datosModificarPersonal.contrasena"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalModificarPersonal = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal size="xl" color="primario" :show.sync="modalDocumentacion">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Documentación </span>
            </h6>
            <CButtonClose @click="modalDocumentacion = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(cargarDocumentacion)">
                <b-row>
                    {{idPersona}}
                    <b-col md="12" class="text-right mb-1">
                        <CButton @click="agregarDocumentacion()" size="sm" color="dark">
                            <i class="fas fa-plus fa-sm"></i> Agregar documento
                        </CButton>
                    </b-col>
                    <b-col md="12">
                        <b-table :items="listaDocumentacion.filter(x => x.estado == 2)" :fields="camposDocumentacion" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template #table-colgroup="camposDocumentacion">
                                <col v-for="field in camposDocumentacion.fields" :key="field.key" :style="{ width: field.key == 'tipo' ? '25%' : field.key == 'nombre' ? '25%' : field.key == 'documento' ? '35%' : '7.5%' }">
                            </template>
                            <template v-slot:cell(tipo)="row">
                                <!-- <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                    <b-form-group label="" class="mb-2">
                                        <v-select label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': row.item.tipo  , 'style-invalid-select is-invalid': !!errors.length}" v-model="row.item.tipo" :options="comboTipoDocumentacion">
                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                        </v-select>
                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                    </b-form-group>
                                </validation-provider> -->
                                <validation-provider :name="'tipo-'+row.index" :rules="{ required: true }" v-slot="validationContext">
                                    <b-form-group>
                                        <b-form-select :state="getValidationState(validationContext)" value-field="idTipo" text-field="descripcion" :options="comboTipoDocumentacion" v-model="row.item.tipo">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </template>
                            <template v-slot:cell(nombre)="row">
                                <validation-provider :name="'nombre-'+row.index" :rules="{ required: true }" v-slot="validationContext">
                                    <b-form-group>
                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model.lazy="row.item.nombre"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </template>
                            <template v-slot:cell(documento)="row">
                                <validation-provider :name="'documento-'+row.index" :rules="{}" v-slot="validationContext">
                                    <b-form-group>
                                        <b-input-group>
                                            <b-input-group-prepend v-if="row.item.urlDocumentacion != ''">
                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumentacion)" v-c-tooltip="'Descargar'">
                                                    <i class="fas fa-download fa-xs"></i>
                                                </b-button>
                                            </b-input-group-prepend>
                                            <b-form-file ref="file" class="text-left" v-model.lazy="row.item.documento" v-on:change="handleFileUpload" :state="getValidationState(validationContext)" :placeholder="row.item.nombreDocumentacion ? row.item.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumentacion ? row.item.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </validation-provider>
                            </template>
                            <template #cell(acciones)="param">
                                <b-button v-if="listaDocumentacion.filter(x => x.estado == 2).length>1" @click="eliminarDocumentacion(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>

                        </b-table>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" class="mr-2" variant="success" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalDocumentacion = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            modalNuevoPersonal: false,
            modalModificarPersonal: false,
            modalDocumentacion: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoPersonal: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "usuario",
                    label: "Usuario",
                    class: "text-center",
                },
                {
                    key: "puestoTrabajo",
                    label: "Puesto de trabajo",
                    class: "text-center",
                },
                {
                    key: "hasUsuario",
                    label: "¿Tiene usuario?",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            camposDocumentacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "documento",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaDocumentacion: [],

            listaPersonal: [],

            comboTipoPerfiles: [],

            acions: [],

            comboPuestosTrabajo: [],

            datosNuevoPersonal: {
                idCliente: '',
                idSubCliente: 0,
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                correo: '',
                telefono: '',
                numeroDocumento: '',
                idTipoDocumento: null,
                idPuestoTrabajo: null,
                hasUsuario: 1,
                idTipoPerfil: null,
                usuario: '',
                contrasena: '',
            },
            datosModificarPersonal: {
                idCliente: '',
                idSubCliente: 0,
                idUsuario: '',
                idCliente: '',
                idPersona: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                correo: '',
                telefono: '',
                numeroDocumento: '',
                idTipoDocumento: null,
                idPuestoTrabajo: null,
                hasUsuario: 1,
                idTipoPerfil: null,
                usuario: '',
                contrasena: '',
            },
            disabled: false,
            comboTipoDocumentacion: [{
                    idTipo: 1,
                    descripcion: 'Formato de entrevista'
                },
                {
                    idTipo: 2,
                    descripcion: 'Cualificación de personal'
                },
                {
                    idTipo: 3,
                    descripcion: 'Cualificación de auditores'
                },
                {
                    idTipo: 4,
                    descripcion: 'Contrato de locación de servicios'
                },
                {
                    idTipo: 5,
                    descripcion: 'Inducción al puesto'
                },
                {
                    idTipo: 6,
                    descripcion: 'Uso de firmas digitales'
                },
                {
                    idTipo: 7,
                    descripcion: 'Ficha de ingreso personal'
                },
                {
                    idTipo: 8,
                    descripcion: 'Compromiso de imparcialidad, confidencialidad, conflictos de intereses y presiones'
                },
                {
                    idTipo: 9,
                    descripcion: 'Derechos y deberes del personal'
                },
                {
                    idTipo: 10,
                    descripcion: 'Declaración jurada sistema previsional de pensión'
                },
                {
                    idTipo: 11,
                    descripcion: 'Estudio de conflictos de interes del personal'
                },
                {
                    idTipo: 12,
                    descripcion: 'Evaluación auditor'
                },
                {
                    idTipo: 13,
                    descripcion: 'Modelo de CV'
                },
            ],
            idPersona: null,
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                //this.listaDocumentoEmpresa.documento = this.$refs.file.files[0];
            }
        },
        descargarDocumento(url) {
            window.open(url)
        },
        agregarDocumentacion() {
           this.listaDocumentacion.push({
                idDocumentacion: 0,
                idPersona: this.idPersona,
                tipo: null,
                nombre: '',
                documento: null,
                urlDocumentacion: '',
                nombreDocumentacion: '',
                estado: 2,
            })
        },
        eliminarDocumentacion(param) {
            let me = this;
            let listaDoc = me.listaDocumentacion.filter(x => x.estado == 2);
            if (listaDoc[param.index].idDocumentacion) {
                listaDoc[param.index].estado = 1;
            } else if (listaDoc[param.index].idDocumentacion == 0) {
                listaDoc[param.index].estado = 0;
                for (let e in me.listaDocumentacion) {
                    if (me.listaDocumentacion[e].estado == 0) {
                        me.listaDocumentacion.splice(e, 1);
                    }
                }
            }
        },
        generarNombreUsuario() {
            let me = this;
            if (me.datosNuevoPersonal.hasUsuario == 2) {
                let usuario = me.datosNuevoPersonal.nombre.substr(0, 1) + me.datosNuevoPersonal.apellidoPaterno + me.datosNuevoPersonal.apellidoMaterno.substr(0, 1);
                me.datosNuevoPersonal.usuario = usuario.toUpperCase();
            }

        },
        generarNombreUsuarioActualizar() {
            let me = this;
            if (me.datosModificarPersonal.hasUsuario == 2) {
                let usuario = me.datosModificarPersonal.nombre.substr(0, 1) + me.datosModificarPersonal.apellidoPaterno + me.datosModificarPersonal.apellidoMaterno.substr(0, 1);
                me.datosModificarPersonal.usuario = usuario.toUpperCase();
            }

        },
        listarPersonal() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/recursoshumanos/listar-usuarios-iqc", {
                    params: {
                        idCliente: this.datosNuevoPersonal.idCliente
                    }
                })
                .then(function (response) {
                    //console.log(response.data)
                    // me.listaPersonal = response.data
                    me.listaPersonal = response.data.filter(x => x.idSubCliente == 0 || x.idSubCliente == null);
                    me.totalRows = me.listaPersonal.length;
                    //me.obtenerDatosEstadisticos();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerTipoDocumento() {
            let me = this;
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-tipo-documento")
                .then(function (response) {
                    me.acions = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerTipoPerfiles() {
            let me = this;
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/recursoshumanos/listar-perfiles", {
                    params: {

                    }
                })
                .then(function (response) {
                    me.comboTipoPerfiles = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/recursoshumanos/listar-puestos-trabajo-iqc", {
                    params: {
                        idCliente: me.datosNuevoPersonal.idCliente
                    }
                })
                .then(function (response) {
                    me.comboPuestosTrabajo = response.data.filter(x => x.nombre != 'Administrador');
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        // nuevoPersonal() {
        //     let me = this;
        //     me.disabled = true;
        //     axios
        //         .post(
        //             CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/registrar-usuario-iqc",
        //             me.datosNuevoPersonal, {
        //                 headers: {
        //                     Authorization: `Bearer ${localStorage.token}`
        //                 },
        //             }
        //         )
        //         .then(function (response) {
        //             let color = response.data.resultado == 1 ? "success" : "error";
        //             me.swat(color, response.data.mensaje)
        //             me.modalNuevoPersonal = false;
        //             me.listarPersonal();
        //             me.disabled = false;
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //             me.disabled = false;
        //         });
        // },
         nuevoPersonal() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/registrar-usuario-iqc",
                    me.datosNuevoPersonal, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.disabled = false;
                    if (response.data.alert) {
                        return me.swat(color, response.data.mensaje)
                    }
                    me.swat(color, response.data.mensaje)
                    me.modalNuevoPersonal = false;
                    me.listarPersonal();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        modificarPersonal() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/modificar-usuario-iqc",
                    me.datosModificarPersonal, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalModificarPersonal = false
                    me.listarPersonal();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        listarDocumentacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/recursoshumanos/listar-documentacion-iqc", {
                    params: {
                        idPersona: me.idPersona
                    }
                })
                .then(function (response) {
                    if (response.data.length > 0) {
                        me.listaDocumentacion = response.data;
                    } else {
                        me.listaDocumentacion.push({
                            idDocumentacion: 0,
                            idPersona: me.idPersona,
                            tipo: null,
                            nombre: '',
                            documento: null,
                            urlDocumentacion: '',
                            nombreDocumentacion: '',
                            estado: 2,
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        cargarDocumentacion() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            let idDoc = 0;

            for (const i in me.listaDocumentacion) {
                if (me.listaDocumentacion[i].idDocumentacion == 0 && me.listaDocumentacion[i].documento != null) {
                    idDoc = idDoc - 1;
                    formData.append("file&&" + idDoc, me.listaDocumentacion[i].documento);
                } else {
                    formData.append("file&&" + me.listaDocumentacion[i].idDocumentacion, me.listaDocumentacion[i].documento);
                }
            }

            formData.append("datosDocumentacion", JSON.stringify(me.listaDocumentacion));
            formData.append("folder", 'recursos-humanos/personal');
            formData.append("idCliente", me.datosNuevoPersonal.idCliente);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/recursoshumanos/registrar-documentacion-iqc",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarDocumentacion();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalNuevoPersonal() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosNuevoPersonal.nombre = '';
            this.datosNuevoPersonal.apellidoPaterno = '';
            this.datosNuevoPersonal.apellidoMaterno = '';
            this.datosNuevoPersonal.correo = '';
            this.datosNuevoPersonal.telefono = '';
            this.datosNuevoPersonal.numeroDocumento = '';
            this.datosNuevoPersonal.idTipoDocumento = null;
            this.datosNuevoPersonal.idPuestoTrabajo = null;
            this.datosNuevoPersonal.hasUsuario = 1;
            this.datosNuevoPersonal.idTipoPerfil = null;
            this.datosNuevoPersonal.usuario = '';
            this.datosNuevoPersonal.contrasena = '';
        },
        resetModalModificarPersonal() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosModificarPersonal.contrasena = '';
        },
        resetModalDocumentacion() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.listaDocumentacion.length = 0;
        },
        abrirModalModificarPersonal(param) {
            let me = this;
            me.datosModificarPersonal.idUsuario = param.item.idUsuario;
            me.datosModificarPersonal.idPersona = param.item.idPersona;
            me.datosModificarPersonal.nombre = param.item.nombre;
            me.datosModificarPersonal.apellidoPaterno = param.item.apellidoPaterno;
            me.datosModificarPersonal.apellidoMaterno = param.item.apellidoMaterno;
            me.datosModificarPersonal.correo = param.item.correo;
            me.datosModificarPersonal.telefono = param.item.telefono;
            me.datosModificarPersonal.numeroDocumento = param.item.numeroDocumento;
            me.datosModificarPersonal.idTipoDocumento = param.item.idTipoDocumento;
            me.datosModificarPersonal.idPuestoTrabajo = param.item.idPuestoTrabajo;
            me.datosModificarPersonal.hasUsuario = param.item.hasUsuario;
            me.datosModificarPersonal.idTipoPerfil = param.item.idTipoPerfil;
            me.datosModificarPersonal.usuario = param.item.usuario;

            me.modalModificarPersonal = true;
        },
        abrirModalDocumentacion(param) {
            this.idPersona=param.item.idPersona
            this.listarDocumentacion();
            this.modalDocumentacion = true;
        },
        eliminarPersonal(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el personal?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/eliminar-usuario-iqc", {
                            idUsuario: param.item.idUsuario,
                            idPersona: param.item.idPersona,
                            estado: 1,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarPersonal();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        restaurarPersonal(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de restaurar el personal?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#F9B115',
                confirmButtonText: 'Restaurar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/restaurar-usuario-iqc", {
                            idUsuario: param.item.idUsuario,
                            idPersona: param.item.idPersona,
                            estado: 2,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarPersonal();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    watch: {
        modalNuevoPersonal: function (val) {
            if (val == false) {
                this.resetModalNuevoPersonal();
            }
        },
        modalModificarPersonal: function (val) {
            if (val == false) {
                this.resetModalModificarPersonal();
            }
        },
        modalDocumentacion: function (val) {
            if (val == false) {
                this.resetModalDocumentacion();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosNuevoPersonal.idCliente = this.datosModificarPersonal.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
            this.obtenerTipoDocumento();
            this.obtenerTipoPerfiles();
            this.listarPersonal();
        }

    }

}
</script>
